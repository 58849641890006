import axios from 'axios'
import { getAuthData, removeToken, refreshTokenRequest } from 'core/auth'

import { getBaseUrl, getDefaultHeaders, getProtectedHeaders } from './middleware'

export const protectedApi = axios.create()

protectedApi.interceptors.request.use(
  async (config) => {
    return getBaseUrl(config).then(getDefaultHeaders).then(getProtectedHeaders)
  },
  (error) => Promise.reject(error),
)

protectedApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      removeToken()
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (getAuthData()?.refresh_token) {
        const config = error.config
        await refreshTokenRequest()
        return await protectedApi.request({
          method: config.method,
          url: config.url,
          data: config.data,
        })
      }
    }
    return Promise.reject(error)
  },
)
