import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Navbar } from 'reactstrap'

import { AppRoutes } from 'constants/appRoutes'
import { AuthContext } from 'containers/App/App'
import { logout, setUrlPathForRedirection, ssoLogout } from 'core/auth'
import NavItem from 'react-bootstrap/NavItem'

export const Header = () => {
  const location = useLocation()
  const [region, setRegion] = useState<string>(window.env('REGION'))

  const handleSelectRegion = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const currentLoginUrl = window.env('REGIONS')[region]['LOGIN_URL']
    const selectedRegion = event.target.value
    setRegion(selectedRegion)
    window.setRegion(selectedRegion)
    const regionSettings = window.env('REGIONS')[selectedRegion]
    const authRedirectionUrl =
      currentLoginUrl +
      '/sso/redirection/regions/' +
      regionSettings['SSO_REGION'] +
      '?redirect_uri=' +
      encodeURIComponent(window.location.origin)
    setUrlPathForRedirection(window.location.pathname + window.location.search)
    logout()
    window.location.assign(authRedirectionUrl)
  }

  const authData = useContext(AuthContext)
  const handleLogoutClick = () => {
    authData.setIsAuthorized?.(false)
    authData.setAuthData?.(null)
    ssoLogout()
  }

  return location.pathname === AppRoutes.login ? null : (
    <Navbar color="light" light>
      <Navbar>
        <Link className="navbar-brand" to={AppRoutes.root}>
          Home
        </Link>
        <NavItem className="ml-5">
          Region:
          <select name="region" onChange={handleSelectRegion} value={region}>
            {Object.keys(window.env('REGIONS')).map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
        </NavItem>
      </Navbar>
      <NavItem>
        <button onClick={handleLogoutClick}>Logout</button>
      </NavItem>
    </Navbar>
  )
}
