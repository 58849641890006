import { AxiosRequestConfig } from 'axios'
import { getToken, isLoggedIn, ssoLogout } from 'core/auth'

export const getProtectedHeaders = async (config: AxiosRequestConfig) => {
  const token = getToken()

  if (isLoggedIn()) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    }
  } else {
    ssoLogout()
  }

  return config
}
