import React, { useContext, useState } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'
import { StandardErrorResponse } from 'types/APIResponses'

export const CaseDoctor = () => {
  const { casesPageState, getCase } = useContext(CasesPageContext)
  const { id, doctor } = casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [doctorUsername, setDoctorUsername] = useState(doctor.username)
  const handleChangeDoctorUsername = (event: React.ChangeEvent<HTMLInputElement>) =>
    setDoctorUsername(event.target.value)

  const handleTransferPatient = () => {
    API.updateCaseById({ id, username: doctorUsername })
      .then(() => {
        getCase()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>Doctor</td>
      <td>
        <form>
          <input
            type="text"
            value={doctorUsername}
            className="mr-2 w-25"
            onChange={handleChangeDoctorUsername}
          />
          {doctor.firstName} {doctor.lastName}
          <button onClick={handleTransferPatient} className="float-right" type="button">
            Transfer
          </button>
        </form>
      </td>
    </tr>
  )
}
