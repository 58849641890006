import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'

import { AppRoutes } from 'constants/appRoutes'
import { AuthContext } from 'containers/App/App'
import BigSpinner from 'resources/img/spinner-big.gif'

export const LoginPage = () => {
  const navigate = useNavigate()
  const authData = useContext(AuthContext)

  if (authData.isAuthorized) {
    navigate(AppRoutes.root)
  }

  return (
    <Container className="w-50 mt-5">
      <h2>Sign In</h2>
      <img src={BigSpinner} className="d-block m-auto" height="100px" alt="spinner" />
    </Container>
  )
}
