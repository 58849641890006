import { ProductInfo } from 'domains/productInfo'

export const filterProducts = (
  selectedProductId: ProductInfo[keyof ProductInfo],
  productId: ProductInfo[keyof ProductInfo],
) => {
  if (
    selectedProductId == ProductInfo['Spark Passive Aligners'] ||
    selectedProductId == ProductInfo.Retainers
  ) {
    return true
  }

  return productId !== ProductInfo['Spark Passive Aligners'] && productId !== ProductInfo.Retainers
}
