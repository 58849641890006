import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { AppRoutes } from 'constants/appRoutes'
import { AuthContext } from 'containers/App/App'

export const PrivateRoute = () => {
  const authData = useContext(AuthContext)

  return authData.isAuthorized ? <Outlet /> : <Navigate to={AppRoutes.login} />
}
