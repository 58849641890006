import React, { useContext } from 'react'

import { AuthContext } from 'containers/App/App'
import { getUserProfile } from 'core/auth'

export const Principal = () => {
  const { isAuthorized } = useContext(AuthContext)
  const token = isAuthorized ? getUserProfile() : null

  return (
    <span className="text-muted text-left text-xl-left">
      {token ? `User: ${token.user_name} [ ${token.authorities} ]` : 'Not logged in'}
    </span>
  )
}
