import React, { createContext, useEffect, useState } from 'react'

import { Layout } from 'containers/Layout'
import {
  authenticate,
  getAuthData,
  getUrlPathForRedirection,
  isLoggedIn,
  logout,
  removeUrlPathForRedirection,
  TokenGrantResult,
} from 'core/auth'
import { history } from 'core/history'

import { HistoryProvider, RequestToasterProvider } from '../Providers'

/**
 * Providers should be initialized here
 */

type authData = TokenGrantResult | null

interface AuthContextData {
  isAuthorized: boolean
  authData: authData
  setIsAuthorized?: (val: boolean) => void
  setAuthData?: (val: authData) => void
}

export const AuthContext = createContext<AuthContextData>({
  isAuthorized: isLoggedIn(),
  authData: getAuthData(),
})

export const App = () => {
  useEffect(() => {
    const regions = Object.keys(window.env('REGIONS'))
    const hasRegion = regions.some((region) => region === window.env('REGION'))

    if (!hasRegion) {
      window.setRegion(regions[0])
      window.location.reload()
    }
  }, [])

  const authCode = new URL(window.location.href).searchParams.get('code') ?? ''
  const [isAuthorized, setIsAuthorized] = useState(isLoggedIn())
  const [authData, setAuthData] = useState(getAuthData())
  const authContextValue = {
    isAuthorized,
    authData,
    setIsAuthorized,
    setAuthData,
  }

  useEffect(() => {
    if (!isAuthorized) {
      authenticate(authCode)
        .then((authData) => {
          setIsAuthorized(true)
          setAuthData(authData)
        })
        .catch(() => {
          logout()
          setIsAuthorized(false)
          setAuthData(null)
        })
    }
  }, [])

  useEffect(() => {
    if (isAuthorized) {
      const urlPath = getUrlPathForRedirection()
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (urlPath) {
        removeUrlPathForRedirection()
        window.location.assign(window.location.origin + urlPath)
      }
    }
  }, [isAuthorized])

  return (
    <HistoryProvider history={history}>
      <RequestToasterProvider>
        <AuthContext.Provider value={authContextValue}>
          <Layout />
        </AuthContext.Provider>
      </RequestToasterProvider>
    </HistoryProvider>
  )
}
