type PrimitiveValues = null | undefined | string | number | boolean | symbol | bigint

export const getSanitizedQueryParams = (
  initObj: Record<string, PrimitiveValues>,
): URLSearchParams => {
  const sanitizedInitObj = Object.entries(initObj).reduce((acc, [key, value]) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (value || value === 0 || value === false) {
      acc[key] = String(value)
    }

    return acc
  }, {} as Record<string, string>)

  return new URLSearchParams(sanitizedInitObj)
}
