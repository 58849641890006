import axios, { AxiosResponse } from 'axios'
import { AuthEncodedToken } from 'domains/auth'
import decode from 'jwt-decode'
import { getSanitizedQueryParams } from 'utils/getSanitizedQueryParams'
import { isNotNil } from 'utils/isNotNil'

const tokenName = 'accessToken'
const authDataKeyName = 'authData'
const urlPathKeyName = 'urlPath'

export const setToken = (idToken: string) => {
  localStorage.setItem(tokenName, idToken)
}

export const getToken = () => {
  return localStorage.getItem(tokenName)
}

export const removeToken = () => {
  return localStorage.removeItem(tokenName)
}

export const setAuthData = (authData: TokenGrantResult) => {
  localStorage.setItem(authDataKeyName, JSON.stringify(authData))
  setToken(authData.access_token)
}

export const getAuthData = () => {
  const authData = localStorage.getItem(authDataKeyName)
  return authData != null ? JSON.parse(authData) : null
}

export const removeAuthData = () => {
  localStorage.removeItem(authDataKeyName)
}

export const getRefreshToken = () => getAuthData()?.refresh_token

export const logout = () => {
  removeAuthData()
  removeToken()
}

export const isLoggedIn = () => {
  const token = getToken()
  return isNotNil(token)
}

export const getUserProfile = () => {
  const token = getToken()
  return isNotNil(token) ? decode<AuthEncodedToken>(token) : null
}

export const setUrlPathForRedirection = (urlPath: string) =>
  localStorage.setItem(urlPathKeyName, urlPath)
export const getUrlPathForRedirection = () => localStorage.getItem(urlPathKeyName)
export const removeUrlPathForRedirection = () => localStorage.removeItem(urlPathKeyName)

export const AUTHORIZE_ENDPOINT = new URL('/oauth2/authorize', window.env('LOGIN_URL'))
export const TOKEN_ENDPOINT = new URL('/oauth2/token', window.env('LOGIN_URL'))
export const LOGOUT_ENDPOINT = new URL('/sso/logout', window.env('LOGIN_URL'))

const UI_CLIENT_ID = 'support_tool'

export interface TokenGrantResult {
  access_token: string
  expires_in: number
  jti: string
  refresh_token: string
  scope: string
  token_type: string
}

type FormDataValues = Record<string, string>

export function createFormData(formValues: FormDataValues): FormData {
  const formData = new FormData()

  for (const [key, value] of Object.entries(formValues)) {
    formData.set(key, value)
  }

  return formData
}

const redirectToLoginPage = () => {
  const authUrl = AUTHORIZE_ENDPOINT
  const { searchParams } = authUrl

  authUrl.search = getSanitizedQueryParams({
    ...Object.fromEntries(searchParams),
    response_type: 'code',
    client_id: UI_CLIENT_ID,
    redirect_uri: window.location.origin,
    client_secret: 'public',
  }).toString()

  window.location.href = authUrl.href
}

const getTokenByCode = async (authCode: string) => {
  const response = await axios.post<TokenGrantResult>(
    TOKEN_ENDPOINT.href,
    createFormData({
      code: authCode,
      grant_type: 'authorization_code',
      client_id: UI_CLIENT_ID,
      redirect_uri: window.location.origin,
      client_secret: 'public',
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  )

  setToken(response.data.access_token)
  setAuthData(response.data)
  return response.data
}

export const refreshTokenRequest = async () => {
  const response = (await axios
    .post<TokenGrantResult>(
      TOKEN_ENDPOINT.href,
      createFormData({
        grant_type: 'refresh_token',
        refresh_token: getRefreshToken(),
        client_id: UI_CLIENT_ID,
        client_secret: 'public',
      }),
    )
    .catch(() => {
      ssoLogout()
    })) as AxiosResponse<TokenGrantResult>

  setToken(response.data.access_token)
  setAuthData(response.data)

  return response
}

export const authenticate = async (authCode: string) => {
  if (!authCode) {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (getRefreshToken()) {
      return await refreshTokenRequest()
    }
    redirectToLoginPage()
  }

  return getTokenByCode(authCode)
}

export const ssoLogout = () => {
  logout()
  LOGOUT_ENDPOINT.searchParams.set('redirect_uri', window.location.origin)
  window.location.assign(LOGOUT_ENDPOINT.href)
}
