import {
  getAccountSearchResults,
  updateAccountParams,
  changeAccountPermissions,
  importAccountDSS,
  importAccountOFR,
  getAccountShipAddresses,
  exportAccountAddress,
  importAccountAddress,
  updateAccountAddressExternalId,
} from './account'
import { getAccountsActiveAddresses, importAccountsExternalAddressIds } from './accounts'
import {
  getCaseById,
  getCaseStatusRollbackMatrix,
  getCasePhotos,
  updateCaseById,
  updateCasePatientById,
  deleteCasePhoto,
  uploadCasePhoto,
  fixCasePhotos,
  uploadCaseScan,
  deleteCaseScan,
  getCasePrintId,
  printCaseResponse,
  confirmCaseShip,
  getCaseScans,
  getCasesByUsername,
  toggleErp,
  mesDesignApproval,
  deleteComboTreatmentByParentId,
} from './case'
import { getFeatureFlags } from './featureFlags'
import { getFileCasesList, getFilePreSignUrl, getFilePutCases } from './file'
import {
  getDoctorsITero,
  deleteDoctorITero,
  postDoctorITeroOrder,
  getITeroMatches,
  getITeroNotMatchedOrders,
  postITeroScans,
} from './itero'
import { getMaterials } from './materials'
import { searchPVS, sendPVSTicket } from './pvs'
import { resetPassword } from './resetPassword'
import { DeleteScanner, getScanners, postNewScanner, updateScannerById } from './scanner'
import { getShippingProviders } from './shippingProviders'
import { getSSOAuthenticationFormUrl, getSSOAuthenticate } from './signIn'
import { getSupports, updateSupport } from './support'
import { getSupportUrls } from './supportUrls'
import { getAccountTerritory } from './territory'
import { getTreatment, updateTreatment } from './treatment'
import {
  getUser,
  getUsersList,
  updateUser,
  updateUserPermissions,
  updateLanguageRegion,
  createAdmin,
} from './user'
import { getVersion } from './version'
import { getWebContent, updateWebContent } from './webContent'

export const API = {
  getFeatureFlags,
  getSSOAuthenticate,
  getSSOAuthenticationFormUrl,
  getDoctorsITero,
  deleteDoctorITero,
  postDoctorITeroOrder,
  getITeroMatches,
  getITeroNotMatchedOrders,
  postITeroScans,
  getAccountTerritory,
  getAccountSearchResults,
  updateAccountParams,
  changeAccountPermissions,
  importAccountDSS,
  importAccountOFR,
  getAccountShipAddresses,
  exportAccountAddress,
  importAccountAddress,
  updateAccountAddressExternalId,
  getWebContent,
  updateWebContent,
  getVersion,
  getCaseById,
  getCaseStatusRollbackMatrix,
  getMaterials,
  getCasePhotos,
  updateCaseById,
  deleteComboTreatmentByParentId,
  updateCasePatientById,
  getCasePrintId,
  uploadCasePhoto,
  deleteCasePhoto,
  deleteCaseScan,
  fixCasePhotos,
  getCasesByUsername,
  uploadCaseScan,
  printCaseResponse,
  confirmCaseShip,
  getTreatment,
  toggleErp,
  mesDesignApproval,
  updateTreatment,
  getFileCasesList,
  getFilePreSignUrl,
  getFilePutCases,
  getCaseScans,
  searchPVS,
  sendPVSTicket,
  getScanners,
  updateScannerById,
  postNewScanner,
  DeleteScanner,
  getSupports,
  updateSupport,
  getUsersList,
  getUser,
  updateUser,
  updateUserPermissions,
  updateLanguageRegion,
  resetPassword,
  getAccountsActiveAddresses,
  importAccountsExternalAddressIds,
  createAdmin,
  getShippingProviders,
  getSupportURLs: getSupportUrls,
}
